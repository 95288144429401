const Strategy = () => (
  <section className="flex justify-center w-full py-12 md:py-24 lg:py-32 border-t">
    <div className="container grid items-center gap-4 px-4 text-center md:px-6 lg:gap-10">
      <div className="space-y-3">
        <div className="space-y-2">
          <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
            A nossa estratégia
          </h2>
        </div>

        <div className="grid gap-1">
          <h3 className="text-2xl font-bold">Análise do problema</h3>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            Identificamos as necessidades específicas de cada negócio.
          </p>
        </div>

        <div className="grid gap-1">
          <h3 className="text-2xl font-bold">Proposta de solução</h3>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            Apresentamos propostas personalizadas para cada desafio.
          </p>
        </div>

        <div className="grid gap-1">
          <h3 className="text-2xl font-bold">Desenvolvimento</h3>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            Utilizamos a tecnologia mais recente para criar as nossas soluções.
          </p>
        </div>

        <div className="grid gap-1">
          <h3 className="text-2xl font-bold">Avaliação</h3>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            Testamos e refinamos até garantir a qualidade e eficácia do projeto.
          </p>
        </div>

        <div className="grid gap-1">
          <h3 className="text-2xl font-bold">Entrega</h3>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            Entregamos o projeto final para impulsionar o sucesso de cada
            negócio.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Strategy;
