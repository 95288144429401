/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => (
  <header className="px-4 lg:px-6 h-14 flex items-center bg-black">
    <a className="flex items-center justify-center" href="#">
      <img src="./favicon.png" className="h-12 w-18" />
    </a>
  </header>
);

export default Header;
