function InstagramIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
    </svg>
  );
}

const Contacts = () => (
  <section className="flex justify-center w-full py-12 md:py-24 lg:py-32 border-t">
    <div className="container grid items-center gap-4 px-4 text-center md:px-6 lg:gap-10">
      <div className="space-y-3">
        <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
          Contacte-nos
        </h2>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Entre em contacto connosco para saber como podemos ajudar o seu
          negócio a ser mais produtivo
        </p>
      </div>

      <div className="mx-auto w-full max-w-[400px] space-y-4">
        <form className="space-y-4">
          <a
            className="inline-flex h-9 items-center justify-center rounded-md  bg-white px-4 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50"
            href="https://www.instagram.com/cloudfull.dev/"
          >
            <InstagramIcon className="h-6 w-6" />
          </a>
          {/*  <div className="grid grid-cols-1 gap-4">
            <Input placeholder="Nome" type="text" />
            <Input placeholder="E-mail" type="email" />
          </div>
          <Textarea className="min-h-[150px]" placeholder="Mensagem" />
          <Button type="submit">Enviar</Button>*/}
        </form>
      </div>
    </div>
  </section>
);

export default Contacts;
