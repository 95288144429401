const Projects = () => (
  <section className="flex justify-center w-full py-12 md:py-24 lg:py-32 border-t">
    <div className="container grid items-center gap-4 px-4 text-center md:px-6 lg:gap-10">
      <div className="space-y-3">
        <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
          Os nosso projectos
        </h2>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Ajudamos os negócios a simplificar as suas rotinas
        </p>
      </div>
      <div className="mx-auto grid max-w-sm items-center gap-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4">
        <a
          className="flex flex-col items-center p-4 rounded-lg border border-gray-200 border-gray-200 shadow-sm hover:shadow transition-colors hover:bg-gray-100 hover:border-gray-200 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950"
          href="https://apps.apple.com/us/app/barbearia-do-professor-vit%C3%B3/id1528176351"
        >
          <img
            alt="Project"
            className="aspect-[1/1] rounded-lg overflow-hidden object-cover object-center"
            height="150"
            src="/bpf-favicon.jpeg"
            width="150"
          />
          <span className="text-sm font-semibold mt-2">iOS</span>
          <span className="text-sm font-semibold mt-2">
            Barbearia Professor Vitó
          </span>
        </a>
        <a
          className="flex flex-col items-center p-4 rounded-lg border border-gray-200 border-gray-200 shadow-sm hover:shadow transition-colors hover:bg-gray-100 hover:border-gray-200 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950"
          href="https://play.google.com/store/apps/details?id=com.vitoplannermobile"
        >
          <img
            alt="Project"
            className="aspect-[1/1] rounded-lg overflow-hidden object-cover object-center"
            height="150"
            src="/bpf-favicon.jpeg"
            width="150"
          />
          <span className="text-sm font-semibold mt-2">Android</span>
          <span className="text-sm font-semibold mt-2">
            Barbearia Professor Vitó
          </span>
        </a>
        <div
          className="flex flex-col items-center p-4 rounded-lg border border-gray-200 border-gray-200 shadow-sm hover:shadow transition-colors hover:bg-gray-100 hover:border-gray-200 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950"
          onClick={() => (window.location.href = "./bpf-web.png")}
        >
          <img
            alt="Project"
            className="aspect-[1/1] rounded-lg overflow-hidden object-cover object-center"
            height="150"
            src="/bpf-favicon.jpeg"
            width="150"
          />
          <span className="text-sm font-semibold mt-2">Web</span>
          <span className="text-sm font-semibold mt-2">
            Barbearia Professor Vitó
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default Projects;
