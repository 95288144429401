import {
  Contacts,
  Footer,
  Header,
  Strategy,
  Intro,
  Projects,
  Services,
} from "./sections";

function App() {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <Header />

      <main className="flex-1">
        <Intro />

        <Services />

        <Strategy />

        <Projects />

        <Contacts />
      </main>

      <Footer />
    </div>
  );
}

export default App;
