const Services = () => (
  <section className="flex justify-center w-full py-12 md:py-24 lg:py-32 border-t">
    <div className="container grid items-center gap-4 px-4 text-center md:px-6 lg:gap-10">
      <div className="space-y-3">
        <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
          O nosso serviço
        </h2>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Fornecemos serviços de desenvolvimento de software, desde a análise,
          planeamento e testes, até à entrega final do projeto.
        </p>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Impulsionamos os nossos projectos com inteligência artificial para
          optimizar o desenvolvimento
        </p>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Planeamos objetivos e entregamos resultados de forma cíclica
          semanalmente
        </p>
      </div>
    </div>
  </section>
);

export default Services;
