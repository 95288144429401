const Intro = () => (
  <section className="flex justify-center w-full flex justify-center py-12 md:py-24 lg:py-32 xl:py-48">
    <div className="container grid items-center gap-6 px-4 text-center md:px-6 lg:gap-10">
      <div className="space-y-3">
        <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
          Cloudfull
        </h1>
        <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Soluções para negócios mais ágeis e eficientes em 5 etapas
        </p>
      </div>
    </div>
  </section>
);

export default Intro;
